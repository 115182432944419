/* general styling */

/* h1 {
  font-weight: normal;
  letter-spacing: 0.125rem;
} */

.li {
  display: inline-block;
  font-size: 10px !important;
  /* list-style-type: none; */
  border: 1px solid transparent;
  border-radius: 100px;
  background-color: var(--accentColor);
  /* background-color: white; */
  width: 45px;
  height: 45px;
  text-align: center;
  padding-top: 9px;
  margin: 0px 5px;
  color: white;
  line-height: 1.1;
}

.li .number {
  font-size: 16px;
  font-weight: bold;
}

.ltr {
  font-size: 8px;
  /* padding-top: 1px; */
}

.span {
  /* display: block; */
  /* font-size: 12px; */
}

.emoji {
  display: none;
  padding: 1rem;
}

.emoji span {
  font-size: 4rem;
  padding: 0 0.5rem;
}
@media only screen and (max-width: 600px) {
  .li {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 1px;
  }
  .li:first-child {
    padding: 5px;
  }
  .li .number {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    position: relative;
    left: 1px;
    top: 2px;
    /* margin: 0px auto; */
    /* padding: 0px auto; */
  }
  .li > div {
    letter-spacing: 1px;
    /* position: relative;
    left: 5px; */
  }
  .ltr {
    font-size: 6px;
    padding-top: 2px;
  }
  .ltr span {
    display: none;
  }
  .li:last-child {
  }
}
