@font-face {
  font-family: "MyFont";
  src: local("MyFont"), url("/public/assets/fonts/Larsseit.otf");
}
/* custom styling css */
.rd-navbar-static {
  padding: 0px !important;
  box-shadow: none!important;
}
.cs-header-padding {
  padding: 15px 0px!important;
}
.rd-navbar-static .rd-navbar-nav > li {
  padding: 0 24px!important;
}
.rd-navbar .rd-navbar-nav > li > a {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: .01em;
}
.rd-navbar, .rd-navbar.rd-navbar--is-clone {
  display: block;
}

/* .rd-navbar-nav-wrap{
  justify-content: flex-end!important;
} */

.rd-navbar-nav-wrap__element {
  margin: -13px -13px 0;
}

.rd-navbar-nav-wrap__element .button {
  margin-top: 16px!important;
}

a.icon.novi-icon.icon-md {
  margin: 0px 20px 0px 0px;
}

.footer-modern__layer.footer-modern__layer_top {
  border-top: 1px solid #ececee;
}

.heading-title {
  font-weight: 700;
}

.list-nav > li a {
  font-weight: 400;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}