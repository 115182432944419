.rd-navbar-nav-wrap__element .button-primary {
  position: relative;
  right: 2px;
  top:-7px;
}
.icon {
  color: black;
}
.linear-icon-cart {
  padding-right: 2px;
}
.linear-icon-user {
  padding-right: 5px;
}
.text-capitalize {
  font-size: 14px;
}
.mobile {
  display: none;
}
.footer {
  display: none;
}

.icon:hover,
.novi-icon:hover,
.icon-md:hover {
  cursor: pointer;
  color: var(--accentColor);
}
.active {
  color: var(--accentColor) !important;
}
.navbar-collapse {
  text-align: center;
}
.navbar-collapse {
  justify-content: center;
}
.navbar-light .navbar-nav .nav-link {
  color: #000;
  font-size: 16px;
}
@media (max-width: 992px) {
  .navbar-toggler {
    border: none;
    background: transparent !important;
  }
  .navbar-toggler .icon-bar {
    background-color: #000;
  }
  .navbar-toggler:hover {
    background: transparent !important;
  }
  .navbar-toggler .icon-bar {
    width: 26px;
    transition: all 100ms;
    height: 3px;
    display: block;
    border-radius: 1px;
  }
  .navbar-toggler .icon-bar+.icon-bar {
    margin-top: 4px;
}
  .navbar-toggler .top-bar {
    transform: rotate(0);
    transform-origin: 10% 10%;
  }
  .navbar-toggler .middle-bar {
    opacity: 1;
  }
  .navbar-toggler .bottom-bar {
    transform: rotate(0);
    transform-origin: 10% 90%;
  }
  .navbar-toggler.active .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;    
  }
  .navbar-toggler.active .middle-bar {
    opacity: 0;
  }
  .navbar-toggler.active .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;

  }
  
  .navbar-collapse {
    background-color: #fff;
      position: fixed;
      top: 86px; /* adjust to height of navbar */
      left: 0;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 15px;
      width: 75%;
      height: 100%;
      box-shadow: 0 2px 12px rgb(136 136 136 / 10%);
  }

  .navbar-collapse.collapsing {
      left: -75%;
      transition: height 0.15s ease;
  }

  .navbar-collapse.show {
      left: 0;
      transition: left 0.15s ease-in-out;
  }

  .navbar-toggler.collapsed ~ .navbar-collapse {
      transition: left 0.15s ease-in-out;
  }
}
@media only screen and (max-width: 600px) {
  .rd-navbar-nav-wrap__element a.novi-icon {
    display: none;
  }
  .navbar-nav .nav-link {
    padding: 11px 56px 11px 18px;
    text-align: left;
  }
  .navbar {
    border-bottom: 1px solid rgb(233, 233, 233);
    position: fixed;
    top: -3px;
    left: 0;
    width: 100%;
    background: #fff;
  }
  .carousel  {
    margin-top: 100px;
  }
  /* .active {
    color: #fff !important;
  } */
  .footer {
    display: flex;
  }
  .mobile {
    display: flex;
  }
  .timer {
    display: flex;
    justify-content: center;
    width: 40%;
    margin-left: 0;
  }
  .btn-success {
    color: white !important;
    border: none !important;
    background-color: rgba(47, 211, 174, 0.5) !important;
    font-size: 12px;
    width: 92px;
    height: 35px;
    border-radius: 0px;
  }
  /* .navbar-toggler {
    font-size: 10px;
    font-size: 12px;
    width: 90px;
    height: 35px;
    border-radius: 0px;
  } */
  .navbar-toggler {
    font-size: 18px;
    padding: 0;
    border: none;
    outline: 0;
    box-shadow: none !important;
  }
}

@media (max-width:359px) {
  .rd-navbar-nav-wrap__element .button-primary {
    padding: 12px 10px;
  }
  .timer {
    margin-left: 0;
    margin-right: 15px;
  }
}
