@font-face {
  font-family: "MyFont";
  src: local("MyFont"), url("/public/assets/fonts/Larsseit.otf");
}
@font-face {
  font-family: "boldFont";
  src: local("MyFont"), url("/public/assets/fonts/Larsseit-Bold.otf");
}

.global-loader {
  background: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1002;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  transition: all 1s ease;
}

.global-loader .logo {
  width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  transition: all 1s ease;
}

.global-loader.pageloaded {
  opacity: 0;
  z-index: -1;
}

.global-loader.pageloaded .logo {
  opacity: 0;
  transform: translate(-50%, 50%);
}

.welcome-text p {
  margin-top: 10px;
}

.winningBidText {
  margin-top:10px;
}
/* .section {
  padding-top: 0px;
} */
/* .container {
  padding: 0px;
} */

p.dateText{  
  margin-top:6px;
  margin-bottom:-10px;
  color: #000000;  
}
div.welcomeText{
  color: #000000;  
}

@media only screen and (max-width: 600px) {

  div.welcomeText{
    color: #000000;
    line-height: 32px;    
    padding-bottom: 30px;
    font-size: 18px;
  }

}
