.slider-img {
  height: 600px;
  width: 100%;
}

.cs.progress-linear .progress-header p {
  font-size: 24px;
  font-weight: 700;
}
.cs.progress-linear .progress-header p span {
  font-size: 12px;
  font-weight: 500;
}
.cs.progress-linear .progress-value {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

.cs.progress-linear .progress-value:after {
  content: "";
}

.cs-overlap {
  position: relative;
  height: 700px;
}

.no-funds-raised-spacer{
  margin-bottom:15px;
}

.inner {
  position: relative;
  width: 100%;
  z-index: 2;
  bottom: 40px;
}
.progress-header {
  padding: 0 7px;
}
.progress-header > p {
  font-family: "boldFont";
}
.progress-header > p > span {
  font-weight: 600 !important;
  font-family: "MyFont";
}
.progress-value {
  font-family: "MyFont";
}
.progress-bar-linear-wrap {
  margin-top: 0px !important;
  
}
.glyphicon-chevron-right {
  display: none !important;
}
@media only screen and (max-width: 600px) {
  .inner {
    /* display: none; */
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 2;
    position: relative;
    bottom: 20px;
  }
  .progress-header > p {
    font-family: "boldFont";
    font-size: 12px !important;
  }
  .progress-header > p > span {
    font-family: "boldFont";
    font-size: 8px !important;
  }
  .progress-value {
    font-size: 8px !important;
  }
  .group-counter {
    height: 43px;
    width: 275px;
  }
  .carousel-indicators > li {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }
  /* .active {
    background-color: #2fd3ae !important;
  } */
  .progress-linear .progress-bar-linear-wrap {
    /*background-color: white !important;*/
    margin-left:-10px;
    margin-right:-10px;
  }

  .progress-linear .progress-header{
    margin-left:-25px;
    margin-right:-25px;
    margin-top:-5px;
  }

.offSetCounter
{
  margin-top:10px;
  margin-bottom:-20px;
}

}
