img.brands-logo-img {
  height: 260px;
  width: 100%;
}

.brand-section {
  /* padding: 30px 0px; */
  
}

.item {
 display: flex; 
 align-items: center;
 justify-content: center;
 width: 100%;
}

.brand-logo-size{
  height: 100px !important;
  width: 100%;
  max-width: none !important;
  object-fit: scale-down;
}
.brand-link-width{
  width:100%;
}

@media (max-width: 767px) {
  .rd-navbar-nav-wrap__element a.novi-icon {
    display: none !important;
  }
  .rd-navbar-nav-wrap__element {
    margin: 0;
  }

  .brand-logo-size{
    height: 90px !important;
  }

}

@media (max-width: 600px) {
  .brand-logo-size{
    max-height: 30px !important;
  }
  
}

@media (max-width: 400px) {
  .brand-logo-size{
    max-height: 90px !important;
  }
  
}

