.list-nav > li {
  text-transform: capitalize !important;
  font-size: 13px;
  font-weight: 300;
}

.footer {
  position: fixed;
  bottom: 0;
  z-index: 99;
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  border-top: 1px solid #ececee;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 30%);
  /* height: 60px;
  background-color: #f5f5f5;

  border-top: 1px solid transparent;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2); */
}

.footer a {
  color: black;
}

.group-container {
  padding: 0;
  height: 100%;
}

.selectors,
.block {
  height: 100%;
  width: 100%;  
}

.selectors button {
  border: 0;
  border-radius: 0;
  /*background-color: #f8f9fa !important;*/
  background-color: white !important;
  width: 25%;
  margin-left: 0;
}

.selectors button:active {
  border: 0;
}

.selectors button:focus {
  border: 0;
  outline: 0;
  box-shadow: 0 0 0 0px;
}

.active,
.selector-holder {
  display: flex;
  flex-direction: column;
}

.inactive {
  display: none;
}

.selector-holder span {
  font-size: 0.7rem;
}

/* Colors of the buttons*/
.button-active,
.selectors button:hover,
.selectors button:active,
.selectors button:focus,
:target {
  color: #2fd3ae;
}

.button-inactive {
  color: #000;
}
i {
  font-family: "Courier New", Courier, monospace !important;
}

.fa-www:hover {
  color: black;
  background-color: white;
}

.fa-www {
  color: #2fd3ae;
  background-color: transparent;
  border-radius: 10px;
}
.brand .brand-logo-dark {
  width: 200px;
}
@media only screen and (max-width: 600px) {
  .activated {
    background-color: white !important;
    color: #2fd3ae !important;
  }
  .list-nav {
    display: none;
  }
  .footer-modern__layer_top {
    padding: 20px 0px;
  }
  .footer-modern__layer_top .list-inline-xxs {
    margin-top: 10px;
  }

  .footer-modern__layer_bottom {
    margin-bottom: 70px;
    padding: 10px 0px;
  }
  .footer-modern__layer_bottom .list-bordered {
    margin-top: 0px;
  }
  #buttonGroup {
    /*padding: 0px 25px !important;*/
    /*background-color: rgba(232, 232, 232, 0.1);*/
    background-color: white;
  }
  .button-inactive {
    background-color: yellowgreen !important;
  }
}

.silent-auction-icon svg{
  margin-top: -7px;
  height: 57px;
  width: 57px;
}
.silent-auction-icon span{
  margin-top: -19px;
}
